<template>
  <section>
    <div v-if="isLoaded">
      <b-overlay :show="showOverlay" rounded="sm">
        <h2
          v-if="listInsumosSelecionados.length == 0"
          class="mt-5 text-secondary w-100 text-center"
        >
          Nenhum insumo selecionado.
        </h2>

        <table v-else class="table table-hover">
          <thead>
            <tr>
              <th><strong>CTA</strong></th>
              <th><strong>COD PART</strong></th>
              <th>
                <strong>CST PIS</strong>
              </th>
              <th>
                <strong>CST COFINS</strong>
              </th>
              <th>
                <strong>Natureza da Base </strong>
              </th>
              <th>
                <strong>Indicador de Crédito </strong>
              </th>
              <th>
                <button
                  :disabled="isDisabledAll"
                  @click.stop.prevent="
                    callModalConfirmation(
                      'success',
                      'adicionar',
                      listInsumosSelecionados
                    )
                  "
                  class="btn btn-outline-green"
                >
                  Adicionar Todos
                </button>
              </th>
              <th>
                <button
                  :disabled="isDisabledAll"
                  @click.stop.prevent="
                    callModalConfirmation(
                      'error',
                      'remover',
                      listInsumosSelecionados,
                      3
                    )
                  "
                  class="btn btn-outline-danger"
                >
                  Remover todos
                </button>
              </th>
              <th><strong></strong></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in listInsumosSelecionados"
              :key="index"
              :id="`insumo${index}`"
            >
              <td>
                {{ item.cta }}
              </td>
              <td>
                {{ codParticipante }}
              </td>
              <td>
                <b-form-select
                  v-model="dropdownSelects.Pis[index]"
                  :options="optionsCst"
                >
                </b-form-select>
              </td>
              <td>
                <b-form-select
                  v-model="dropdownSelects.Cofins[index]"
                  :options="optionsCst"
                ></b-form-select>
              </td>
              <td>
                <b-form-select
                  :id="`NatBcSelect-${index}`"
                  v-model="dropdownSelects.Natbc[index]"
                  :options="optionsNatbc"
                  @change="removeStyleSelect(index)"
                >
                </b-form-select>
              </td>
              <td>
                <b-form-select
                  v-model="dropdownSelects.Indbc[index]"
                  :options="optionsIndbc"
                ></b-form-select>
              </td>
              <td>
                <button
                  :disabled="isDisabled"
                  @click.stop.prevent="sendInsumo(item, index)"
                  class="btn btn-outline-green w-100"
                >
                  <font-awesome-icon icon="plus"></font-awesome-icon>
                </button>
              </td>
              <td>
                <button
                  :disabled="isDisabled"
                  @click.stop.prevent="removeInsumo(item, 3)"
                  class="btn btn-outline-danger w-100"
                >
                  <font-awesome-icon icon="trash-can"></font-awesome-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </b-overlay>
    </div>

    <div v-else>
      <div class="p-5 d-flex justify-content-center">
        <h1 class="text-secondary d-flex align-items-end">
          <span class="mx-3">Carregando</span>
          <DotAnimation />
        </h1>
      </div>
    </div>
  </section>
</template>

<script>
import emitter from "@/services/emitter";
import DotAnimation from "@/Components/Utils/DotAnimation.vue";
import RestituicaoService from "@/services/teses/restituicao.service";

export default {
  name: "Passo3Restituicao",

  components: {
    DotAnimation,
  },

  data() {
    return {
      listInsumosSelecionados: [],
      dropdownSelects: {
        CodPart: [],
        Pis: [],
        Cofins: [],
        Natbc: [],
        Indbc: [],
      },
      optionsNatbc: [],
      optionsCst: [],
      optionsIndbc: [],
      codParticipante: "",
      showOverlay: false,
      isDisabled: false,
      isDisabledAll: false,
      isLoaded: false,
      hasInsumosSelecionados: false,
    };
  },

  props: {
    nCodOp: String,
    activatePasso3: Number,
  },

  mounted() {
    this.loadPasso3();

    emitter.on("sendAllInsumos", (listInsumos) => {
      this.sendAllInsumos(listInsumos);
    });

    emitter.on("removeAllInsumos", (step) => {
      if (step == 3) {
        this.listInsumosSelecionados = [];
        this.isDisabled = this.isDisabledAll = this.showOverlay = false;
        this.isDisabledAll = this.listInsumosSelecionados.length == 0;
      }
    });

    emitter.on("reloadListInsumos", (params) => {
      if (params.passo == 3) {
        this.listInsumosSelecionados.splice(
          this.listInsumosSelecionados.indexOf(params.item),
          1
        );
        this.isDisabled = this.showOverlay = false;
        this.isDisabledAll = this.listInsumosSelecionados.length == 0;
      }
    });

    emitter.on("cancelLoading", () => {
      this.isDisabled = this.showOverlay = false;
      this.isDisabledAll = this.listInsumosSelecionados.length == 0;
    });
  },

  methods: {
    async loadPasso3() {
      this.listInsumosSelecionados = [];

      RestituicaoService.getCodPart(this.nCodOp).then((response) => {
        console.log("response getCodPart", response);
        this.codParticipante = response.data;
      });

      await RestituicaoService.getOptionsSelects()
        .then((response) => {
          this.optionsCst = response.data.dados.optionsCstsList;
          this.optionsNatbc = response.data.dados.optionsNatbcList;
          this.optionsIndbc = response.data.dados.optionsIndbcList;
        })
        .catch((error) => {
          try {
            const title = "Erro ao buscar opções do select !";
            const msg = error.response.data.mensagem;
            const type = "error";
            emitter.emit("makeModalWithBtnsAndTitle", { title, msg, type });
          } catch (error) {
            const msg = "Erro ao buscar opções do select !";
            const type = "error";
            emitter.emit("makeModalDefault", { msg, type });
          }
          this.isLoaded = true;
        });

      RestituicaoService.getInsumos(this.nCodOp, this.activatePasso3)
        .then((response) => {
          console.log("response-->", response);
          this.listInsumosSelecionados = response.data.dados.listaPlanoI050;
          this.listInsumosSelecionados.forEach((item) => {
            Object.entries(this.dropdownSelects).forEach(([key, value]) => {
              if (["Cofins", "Pis"].includes(key)) {
                value.push("53");
              }

              if (key == "Indbc") {
                value.push("0");
              }
            });
            this.hasInsumosSelecionados =
              this.listInsumosSelecionados.length != 0;
            this.$emit("hasInsumos", this.hasInsumosSelecionados);
          });

          this.isDisabledAll = this.listInsumosSelecionados.length == 0;
          this.isLoaded = true;
          this.$emit("passo3", 3);
        })
        .catch((error) => {
          if (error.response.data != null) {
            const title = "Erro ao buscar insumos !";
            const msg = error.response.data.mensagem;
            const type = "error";

            emitter.emit("makeModalWithBtnsAndTitle", { title, msg, type });
          } else {
            const msg = "Erro ao buscar insumos !";
            const type = "error";

            emitter.emit("makeModalDefault", { msg, type });
          }

          this.isLoaded = true;
        });
    },

    sendInsumo(item, pos) {
      this.isDisabled = true;
      this.isDisabledAll = true;

      let dataInsumos = {
        NCodOp: item.nCodOp,
        CodCta: item.codCta,
        CodPart: this.codParticipante,
        CstPis: this.dropdownSelects.Pis[pos],
        CstCofins: this.dropdownSelects.Cofins[pos],
        NatBcCred: this.dropdownSelects.Natbc[pos],
        IndOrigCred: this.dropdownSelects.Indbc[pos],
        Cta: item.cta,
      };

      if (dataInsumos.NatBcCred == undefined) {
        document
          .querySelector(`#NatBcSelect-${pos}`)
          .classList.add("border-danger");
        this.isDisabled = false;
        this.isDisabledAll = this.listInsumosSelecionados.length == 0;
      } else {
        this.showOverlay = true;
        this.$emit("disabledBtnPasso", true);

        RestituicaoService.sendInsumo(dataInsumos).then((response) => {
          if (response.data == true) {
            this.listInsumosSelecionados.splice(
              this.listInsumosSelecionados.indexOf(item),
              1
            );
            this.dropdownSelects.Pis.splice(
              this.dropdownSelects.Pis.indexOf(dataInsumos.CstPis),
              1
            );
            this.dropdownSelects.Cofins.splice(
              this.dropdownSelects.Cofins.indexOf(dataInsumos.CstCofins),
              1
            );
            this.dropdownSelects.Natbc.splice(
              this.dropdownSelects.Natbc.indexOf(dataInsumos.NatBcCred),
              1
            );
            this.dropdownSelects.Indbc.splice(
              this.dropdownSelects.Indbc.indexOf(dataInsumos.IndOrigCred),
              1
            );
            this.dropdownSelects.CodPart.splice(
              this.dropdownSelects.CodPart.indexOf(dataInsumos.CodPart),
              1
            );
          }
          this.isDisabled = false;
          this.isDisabledAll = this.listInsumosSelecionados.length == 0;
          this.showOverlay = false;
          this.$emit("disabledBtnPasso", false);

          const msg = "Insumo adicionado com sucesso !!";
          const type = "success";
          emitter.emit("makeModalDefault", { msg, type });
        });
      }
    },

    sendAllInsumos(listInsumosSelecionados) {
      this.isDisabled = true;
      this.isDisabledAll = true;
      const dataInsumos = [];
      const dataInsumosError = [];

      try {
        this.listInsumosSelecionados.forEach((item, pos) => {
          let insumo = {
            NCodOp: listInsumosSelecionados[pos].nCodOp,
            CodCta: listInsumosSelecionados[pos].codCta,
            CodPart: this.codParticipante,
            CstPis: this.dropdownSelects.Pis[pos],
            CstCofins: this.dropdownSelects.Cofins[pos],
            NatBcCred: this.dropdownSelects.Natbc[pos],
            IndOrigCred: this.dropdownSelects.Indbc[pos],
            Cta: item.cta,
          };

          insumo.NatBcCred != undefined
            ? dataInsumos.push(insumo)
            : dataInsumosError.push(insumo);
        });

        dataInsumosError.map((item, pos) => {
          document
            .querySelector(`#NatBcSelect-${pos}`)
            .classList.add("border-danger");
          document.querySelector(`#NatBcSelect-${pos}`).value = "";
        });

        if (dataInsumos.length != 0) {
          this.showOverlay = true;
          RestituicaoService.sendAllInsumos(dataInsumos)
            .then((response) => {
              if (response.status == 200 && response.data == true) {
                this.listInsumosSelecionados = dataInsumosError;
                const msg = "Insumos adicionados com sucesso !!";
                const type = "success";
                emitter.emit("makeModalDefault", { msg, type });
              } else {
                const msg = "Erro ao enviar todos os insumos";
                const type = "error";
                emitter.emit("makeModalDefault", { msg, type });
              }
              this.isDisabled = false;
              this.showOverlay = false;
              this.isDisabledAll = this.listInsumosSelecionados.length == 0;
              this.$emit("disabledBtnPasso", false);
            })
            .catch((err) => {
              const title = "Erro ao enviar todos os insumos";
              const text = err;
              const type = "error";
              emitter.emit("makeModalWithBtnsAndTitle", { title, text, type });
              this.isDisabled = false;
              this.showOverlay = false;
              this.isDisabledAll = this.listInsumosSelecionados.length == 0;
              this.$emit("disabledBtnPasso", false);
            });
        } else {
          this.isDisabled = false;
          this.showOverlay = false;
          this.isDisabledAll = this.listInsumosSelecionados.length == 0;
          this.$emit("disabledBtnPasso", false);
        }
      } catch (err) {
        const title = "Erro ao enviar todos os insumos";
        const text = err;
        const type = "error";
        emitter.emit("makeModalWithBtnsAndTitle", { title, text, type });
        this.isDisabled = false;
        this.showOverlay = false;
        this.isDisabledAll = this.listInsumosSelecionados.length == 0;
        this.$emit("disabledBtnPasso", false);
      }
    },

    removeInsumo(item, step) {
      this.isDisabled, this.isDisabledAll, (this.showOverlay = true);
      this.$emit("removeInsumo", { item, step });
    },

    removeStyleSelect(pos) {
      document
        .querySelector(`#NatBcSelect-${pos}`)
        .classList.remove("border-danger");
    },

    callModalConfirmation(typeIcon, typeAction, typeList, step = 0) {
      this.isDisabled = this.isDisabledAll = this.showOverlay = true;
      this.$emit("callModalConfirmation", {
        typeIcon,
        typeAction,
        typeList,
        step,
      });
    },
  },
};
</script>

<style></style>
