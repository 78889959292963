<template>
  <section>
    <b-overlay :show="showOverlay" rounded="sm">
      <div v-if="isLoaded">
        <b-overlay
          :show="loadingSpinner"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="secondary"
          class="d-inline-block width100"
        >
          <div class="upload-container">
            <div class="toggle-list">
              <div class="toggle-upload">
                <button
                  v-if="validate60Meses"
                  @click.prevent="
                    (validate60Meses = false),
                      toggleUploadParameters('validate60Meses')
                  "
                >
                  <Icon :iconName="'toggle-on'" class="toggle-icon-green" />
                </button>
                <button
                  v-if="!validate60Meses"
                  @click.prevent="
                    (validate60Meses = true),
                      toggleUploadParameters('validate60Meses')
                  "
                >
                  <Icon :iconName="'toggle-off'" class="toggle-icon-gray" />
                </button>
                Validar 60 Meses
              </div>

              <div class="toggle-upload">
                <button
                  v-if="validateCnpj"
                  @click.prevent="
                    (validateCnpj = false),
                      toggleUploadParameters('validateCnpj')
                  "
                >
                  <Icon :iconName="'toggle-on'" class="toggle-icon-green" />
                </button>
                <button
                  v-if="!validateCnpj"
                  @click.prevent="
                    (validateCnpj = true),
                      toggleUploadParameters('validateCnpj')
                  "
                >
                  <Icon :iconName="'toggle-off'" class="toggle-icon-gray" />
                </button>
                Validar CNPJ
              </div>
            </div>

            <VueDropzone
              id="uploadComponent"
              class="vue-dropzone"
              ref="UploadComponentDropzone"
              :options="dropzoneOptions"
              @vdropzone-success="handleUploadSuccess"
              @vdropzone-error="handleUploadError"
              @vdropzone-file-added="handleFileAdded"
              @vdropzone-queue-complete="handleCompletedQueue"
              :useCustomSlot="true"
            >
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">
                  Selecione um arquivo ou arraste aqui
                </h3>
                <div class="subtitle">Máximo de 100 arquivos por vez</div>
                <div class="subtitle">Speds Contábil</div>
              </div>
            </VueDropzone>

            <div id="fileList">
              <b-card class="upload-list" no-body>
                <b-button
                  class="upload-list-header"
                  block
                  v-b-toggle.accordion-1
                  variant="light"
                >
                  <div>Contábeis</div>
                  <div class="upload-list-header-buttons">
                    <div class="upload-list-count">
                      {{ this.filesSuccessAdded.length }} Arquivos Enviados
                    </div>
                    <button @click.prevent="deleteContabilFiles($event)">
                      <Icon :iconName="'x'" class="nav-icon" />
                    </button>
                    <button>
                      <Icon :iconName="'chevron-down'" class="nav-icon" />
                    </button>
                  </div>
                </b-button>

                <b-collapse
                  class="upload-list-collapse"
                  id="accordion-1"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <ListUploadFiles
                    ref="contabilFiles"
                    class="listaArquivosEnviados"
                    v-if="hasFilesSended"
                    :listFiles="filesSuccessAdded"
                    :nCodOp="nCodOp"
                    :hasExSended="hasExcelSended"
                    @showOverlay="showOverlay = $event"
                    @cleanList="loadFileList()"
                  />
                </b-collapse>
              </b-card>
            </div>

            <!--ListUploadFiles v-if="hasFilesSended" :listFiles="filesSuccessAdded" :nCodOp="nCodOp" :hasExSended="hasExcelSended"
                        @showOverlay="showOverlay = $event" @cleanList="loadFileList()" /-->
          </div>
        </b-overlay>
      </div>
      <div v-else>
        <div class="p-5 d-flex justify-content-center">
          <h1 class="text-secondary d-flex align-items-end">
            <span class="mx-3">Carregando</span>
            <DotAnimation />
          </h1>
        </div>
      </div>
    </b-overlay>
  </section>
</template>

<script>
import DotAnimation from "@/Components/Utils/DotAnimation.vue";
import HelperService from "@/services/helper.service.js";
import ListUploadFiles from "@/Components/UploadComponent/ListUploadFiles.vue";
import UploadRestituicaoService from "@/services/uploadRestituicao.service.js";
import Vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Icon from "../Icon/icon.vue";
export default {
  name: "UploadComponentRestituicao",
  components: {
    DotAnimation,
    ListUploadFiles,
    VueDropzone: Vue2Dropzone,
    Icon,
  },
  data() {
    return {
      showOverlay: false,
      loadingSpinner: false,
      isLoaded: false,
      tese: "",
      teseAcceptedFiles: "",
      nCodOp: "",
      removerPeriodo: false,
      isCompletedWithoutErrors: "",
      hasFilesSended: false,
      hasExcelSended: false,
      filesSuccessAdded: [],
      isOpen: false,
      toOpenList: {
        file: [],
        response: [],
      },
      periodoAdded: [],
      dropzoneOptions: {
        url: process.env.VUE_APP_URL_API + "/Restituicao/upload/",
        addRemoveLinks: false,
        duplicateCheck: true,
        paralleUploads: 4,
        autoProcessQueue: true,
        acceptedFiles: ".xlsx, .txt",
        paramName: "files",
        params: {
          NCodOp: this.$route.params.nCodOp,
          ValidarCNPJ: true,
          Validar60Meses: true,
          RemoveDuplicates: true,
        },
        timeout: 0,
        maxFiles: 500,
        maxFilesize: 999999999,
      },
      validate60Meses: true,
      validateCnpj: true,
      removeDuplicates: true,
    };
  },
  mounted() {
    this.isLoaded = false;
    this.tese = this.$route.fullPath.split("/")[2];
    this.nCodOp = this.$route.params.nCodOp;
    this.loadFileList();

    switch (this.tese) {
      case "restituicao":
        this.dropzoneOptions.acceptedFiles = ".txt, .xlsx";
        break;
      case "inss":
        this.dropzoneOptions.acceptedFiles = [".txt", ".zip", ".xlsx"];
        break;
      case "icms":
        this.dropzoneOptions.acceptedFiles = [".txt", ".zip", ".xlsx"];
        break;
    }
    return this.dropzoneOptions;
  },
  methods: {
    async loadFileList() {
      await UploadRestituicaoService.getFilesSended(this.nCodOp)
        .then((response) => {
          this.loadingSpinner = false;
          if (response.status == 200) {
            this.filesSuccessAdded = response.data.dados[1];
            this.hasExcelSended = response.data.dados[0];
            this.isLoaded = true;
          }
          this.hasFilesSended = this.filesSuccessAdded.length > 0;
        })
        .catch((error) => {
          // return HelperService.callMakeToast(error, "error");
          this.$toast.open({ message: error, type: "error", duration: 2000 });
        });
    },
    toggleUploadParameters(par) {
      console.log("60m -> ", this.validate60Meses);
      console.log("cnpj -> ", this.validateCnpj);
      switch (par) {
        case "validate60Meses":
          this.dropzoneOptions.params.Validar60Meses = this.validate60Meses;
          break;
        case "validateCnpj":
          this.dropzoneOptions.params.ValidarCNPJ = this.validateCnpj;
          break;
        case "removeDuplicates":
          this.dropzoneOptions.params.RemoveDuplicates = this.removeDuplicates;
          break;
      }
    },
    loadPasso2() {
      this.loadingSpinner = true;
      this.$emit("passo2");
    },
    handleUploadSuccess(file, response) {
      try {
        this.loadingSpinner = false;
        let arrayExt = file.upload.filename.split(".");
        let fileExt = arrayExt.pop();
        let message = "Excel enviado com sucesso";
        if (fileExt != "xlsx") {
          let splitType = file.upload.filename.includes("_") ? "_" : "-";
          let filename = file.upload.filename.split(splitType)[3].trim();
          let year = filename.slice(0, 4);
          message = "Arquivo de " + year + " enviado com sucesso";
        }
        this.$refs.UploadComponentDropzone.removeFile(file);
        this.loadFileList();
        // return HelperService.callMakeToast(message, "success");
        this.$toast.open({ message: message, type: "success", duration: 2000 });
      } catch (error) {
        console.error("error ->", error);
        // return HelperService.callMakeToast(error, "error");
        this.$toast.open({ message: error, type: "error", duration: 2000 });
      }
    },
    async deleteContabilFiles(e) {
      e.stopPropagation();
      let contabilFilesList = this.$refs.contabilFiles;
      contabilFilesList.clearContabilFiles(this.nCodOp);
    },
    handleUploadError(file, response, xhr) {
      var error = "Erro ao enviar";
      console.log("responde -->", response);
      if (response.mensagem != null) error = response.mensagem[0];
      //$('.data-dz-errormessage span ').text(error);
      //TODO: validar qual é o tipo de erro de forma mais específica
      if (response.dados != null && response.dados.length == 2) {
        if (this.isOpen) {
          this.toOpenList.file.push(file);
          this.toOpenList.response.push(response);
        } else {
          this.isOpen = true;
          let arrayExt = file.upload.filename.split(".");
          let fileExt = arrayExt.pop();
          let message = "Excel ";
          let year = "";
          if (fileExt != "xlsx") {
            let splitType = file.upload.filename.includes("_") ? "_" : "-";
            let filename = file.upload.filename.split(splitType)[3].trim();
            year = filename.slice(0, 4);
          } else {
            let filename = "excel";
            year = "0000";
          }
          this.$swal({
            icon: "info",
            title: "CNPJs divergentes !!",
            html: `Arquivo de <strong>${year}</strong> </br> CNPJ do NCodOp: <strong>${response.dados[0]}</strong> </br> CNPJ do Sped: <strong>${response.dados[1]}</strong>
                        </br> Deseja continuar mesmo assim ?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, desejo continuar!",
            cancelButtonText: `Cancelar`,
          }).then((result) => {
            if (result.value) {
              this.loadingSpinner = true;

              let splitType = file.upload.filename.includes("_") ? "_" : "-";
              let periodoToRemove = file.upload.filename
                .split(splitType)[3]
                .trim();
              let index = this.periodoAdded.indexOf(periodoToRemove);
              this.periodoAdded.splice(index, 1);
              this.$refs.UploadComponentDropzone.removeFile(file);
              this.$refs.UploadComponentDropzone.manuallyAddFile(file, "");
              this.callModalIfExits();

              UploadRestituicaoService.uploadFiles(
                file,
                this.tese,
                this.nCodOp,
                true //ignorar CNPJ
              )
                .then((response) => {
                  this.loadingSpinner = false;
                  if (response.status == 200) {
                    let splitType = file.upload.filename.includes("_")
                      ? "_"
                      : "-";
                    let filename = file.upload.filename
                      .split(splitType)[3]
                      .trim();
                    let year = filename.slice(0, 4);
                    this.$refs.UploadComponentDropzone.removeFile(file);
                    let message = "Arquivo de " + year + " enviado com sucesso";
                    this.loadFileList();
                    // return HelperService.callMakeToast(message, "success");
                    this.$toast.open({
                      message: message,
                      type: "success",
                      duration: 2000,
                    });
                  }
                })
                .catch((error) => {
                  this.loadingSpinner = false;
                  this.$refs.UploadComponentDropzone.removeFile(file);
                  if (error.response.data != null) {
                    console.log("e - ", error.response.data);
                    // HelperService.callMakeToast(error.response.data.mensagem[0], "error");
                    this.$toast.open({
                      message: error.response.data.mensagem[0],
                      type: "error",
                      duration: 2000,
                    });
                  } else {
                    // HelperService.callMakeToast("Erro ao enviar", "error");
                    this.$toast.open({
                      message: "Erro ao enviar",
                      type: "error",
                      duration: 2000,
                    });
                  }
                  this.callModalIfExits();
                });
            } else {
              this.$refs.UploadComponentDropzone.removeFile(file);
              this.callModalIfExits();
            }
          });
        }
      } else {
        this.$refs.UploadComponentDropzone.removeFile(file);
        // return HelperService.callMakeToast(error, 'error');
        this.$toast.open({ message: error, type: "error", duration: 2000 });
      }
    },
    handleCompletedQueue() {
      this.periodoAdded = [];
    },
    handleFileAdded(file) {
      document.querySelectorAll(".dz-details").forEach((classe) => {
        classe.style = "background-color: #233873 !important";
      });

      let arrayExt = file.upload.filename.split(".");
      let fileExt = arrayExt.pop();
      if (fileExt != "xlsx") {
        let splitType = file.upload.filename.includes("_") ? "_" : "-";
        let periodo = file.upload.filename.split(splitType)[3].trim();
        let year = periodo.slice(0, 4);

        if (this.periodoAdded.includes(periodo)) {
          let message =
            "Periodo de " + periodo + " removido na validação de duplicidade.";
          // HelperService.callMakeToast(message, "info");
          this.$toast.open({ message: message, type: "info", duration: 2000 });
          return this.$refs.UploadComponentDropzone.removeFile(file);
        }
        return this.periodoAdded.push(periodo);
      }
    },
    callModalIfExits() {
      this.isOpen = false;
      try {
        if (this.toOpenList.file.length > 0) {
          var responseObj = JSON.parse(
            JSON.stringify(this.toOpenList.response[0])
          );
          this.handleUploadError(this.toOpenList.file[0], responseObj, null);
          this.toOpenList.response.splice(0, 1);
          this.toOpenList.file.splice(0, 1);
        }
      } catch (error) {
        // return HelperService.callMakeToast(error, "error");
        this.$toast.open({ message: error, type: "error", duration: 2000 });
      }
    },
    apagarTudoExcel() {
      this.$swal({
        icon: "error",
        title: "Deseja apagar o excel enviado ?",
        html: `Essa ação não poderá ser desfeita.`,
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, desejo continuar!",
        cancelButtonText: `Cancelar`,
      }).then((result) => {
        if (result.value) {
          this.$emit("showOverlay", true);
          UploadRestituicaoService.apagarExcel(this.nCodOp)
            .then((response) => {
              if (response.status == 200) {
                this.hasExcelSended = false;
                this.$emit("cleanList");
                // HelperService.callMakeToast("Excel apagado com sucesso !!", "success");
                this.$toast.open({
                  message: "Excel apagado com sucesso !!",
                  type: "success",
                  duration: 2000,
                });
                return this.$emit("showOverlay", false);
              }
            })
            .catch((error) => {
              this.$emit("showOverlay", false);
              console.log(error.response);
              if (error.response.data.mensagem != null) {
                return HelperService.callMakeModalWithBtnsAndTitle(
                  "Erro ao apagar !",
                  error.response.data.mensagem[0],
                  "error"
                );
              }
              // return HelperService.callMakeToast("Erro ao apagar", "error");
              this.$toast.open({
                message: "Erro ao apagar",
                type: "error",
                duration: 2000,
              });
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.width100 {
  width: 100%;
}

#uploadComponent {
  border: 2px dashed #aaa !important;
  border-radius: 20px;
  color: #aaa;
  background-color: #eee !important;
  width: 60% !important;
  min-height: 180px !important;
  max-height: 230px !important;
  overflow-y: auto !important;
  flex-grow: 1;
  margin: 30px 0px;
  box-shadow: 18px 18px 36px #bebebe, -18px -18px 36px #ffffff;
}
#fileList {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 0px;
  width: 90%;
}
.toggle-list {
  display: flex;
  flex-direction: row;
}
.toggle-upload {
  margin: 40px 30px 0px 30px;
  display: flex;
  align-items: center;
}
.toggle-upload button {
  background: none;
  border: none;
}
.toggle-icon-green {
  font-size: 30px;
  margin: 0px 5px;
  vertical-align: center;
  color: #2bb673;
}
.toggle-icon-gray {
  font-size: 30px;
  margin: 0px 5px;
  vertical-align: center;
  color: #9b9b9b;
}
.upload-list {
  margin: 20px;
  border: none;
  box-shadow: 18px 18px 36px #bebebe, -18px -18px 36px #ffffff;
  border-radius: 10px;
}
.upload-list-header {
  background-color: white;
  color: black;
  border: none;
  border-radius: 10px;
  border-bottom: #233873;
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  padding-left: 30px;
}
.upload-list-header-buttons button {
  background: none;
  border: none;
}

.upload-list-header div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.upload-list-count {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  padding: 15px;
  letter-spacing: -0.02em;

  color: #8e8e8e;
}
.nav-icon {
  padding: 15px;
}

.toast-error-tj {
  background-color: aqua !important;
}

.dz-details {
  background-color: rgb(212, 30, 30) !important;
}

VueDropzone > .dz-preview > .dz-details {
  background-color: #fff !important;
  color: black !important;
}

.upload-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  min-width: 800px;
  width: 100%;
}

.btn-tj-blue {
  color: #233873 !important;
  border: 1px solid #233873 !important;
}
.btn-tj-blue:hover {
  background-color: #233873 !important;
  color: #fff !important;
}
#customdropzone {
  background-color: orange;
  font-family: "Arial", sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color 0.2s linear;
  height: 200px;
  padding: 40px;
}
#customdropzone .dz-preview {
  width: 160px;
  display: inline-block;
}
#customdropzone .dz-preview .dz-image {
  width: 80px;
  height: 80px;
  margin-left: 40px;
  margin-bottom: 10px;
}
#customdropzone .dz-preview .dz-image > div {
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-size: contain;
}
#customdropzone .dz-preview .dz-image > img {
  width: 100%;
}
#customdropzone .dz-preview .dz-details {
  color: white;
  transition: opacity 0.2s linear;
  text-align: center;
}

#customdropzone .dz-success-mark,
.dz-error-mark,
.dz-remove {
  display: none;
}

.listasEnviados {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.listaArquivosEnviados {
  flex-grow: 1;
  margin: 15px;
}
</style>
