<template>
  <section>
    
    <div v-if="isLoaded">
    
      <div class="mt-0 d-flex flex-wrap flex-row justify-content-center text-center align-itens-center mb-3">
        <button class="btn btn-outline-green m-3" :disabled="isDisabledAll" @click="exportF100CTA()">
          Exportar F100 .CSV CTA
        </button>
        <button class="btn btn-outline-blue m-3" :disabled="isDisabledAll" @click="exportF100Notas()">
          Exportar F100 .CSV Notas
        </button>
        <button class="btn btn-outline-green m-3" :disabled="isDisabledAll" @click="exportF100()">
          Exportar F100 .txt
        </button>      
      </div>

      <h4 class="text-center text-secondary">Insumos adicionados ao F100:</h4>
      <b-overlay :show="showOverlay" rounded="sm">
        <h2
          v-if="listAdded.length == 0"
          class="mt-5 text-secondary w-100 text-center"
        >
          Nenhum insumo adicionado ao F100.
        </h2>

        <table v-else class="table table-hover">
          <thead>
            <tr>
              <th><strong>CTA</strong></th>
              <th><strong>Código do Participante</strong></th>
              <th>
                <strong>CST PIS</strong>
              </th>
              <th>
                <strong>CST COFINS</strong>
              </th>
              <th>
                <strong>Natureza da Base </strong>
              </th>
              <th>
                <strong>Indicador de Crédito </strong>
              </th>
              <th>
                <button
                  type="button"
                  :disabled="isDisabledAll"
                  @click="callModalConfirmation('error', 'remover', listAdded, 4)"
                  class="btn btn-outline-danger w-100">
                  Remover Todos
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in listAdded"
              :key="index"
              :id="`insumo${index}`"
            >
              <td>
                {{ item.cta }}
              </td>

              <td>
                {{ item.codPart }}
              </td>

              <td>
                {{ item.cstPis }}
              </td>

              <td>
                {{ item.cstCofins }}
              </td>

              <td>
                {{ item.natBcCred }}
              </td>

              <td>
                {{ item.indOrigCred }}
              </td>

              <td>
                <button
                  type="button"
                  :disabled="isDisabled"
                  @click="removeInsumo(item, 4)"
                  class="btn btn-outline-danger w-100"
                >
                  <font-awesome-icon icon="trash-can"></font-awesome-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </b-overlay>
    </div>

    <div v-else>
      <div class="p-5 d-flex justify-content-center">
        <h1 class="text-secondary d-flex align-items-end">
          <span class="mx-3">Carregando</span>
          <DotAnimation />
        </h1>
      </div>
    </div>
  </section>
</template>

<script>
import DotAnimation from "@/Components/Utils/DotAnimation.vue";
import emitter from "@/services/emitter";
import RestituicaoService from "@/services/teses/restituicao.service";

export default {
  name: "Passo4Restituicao",

  components: {
    DotAnimation,
  },

  data() {
    return {
      listAdded: [],
      isDisabled: false,
      isDisabledAll: false,
      isLoaded: false,
      showOverlay: false,
      hasInsumosAdded: false,
    };
  },

  props: {
    nCodOp: String,
    activatePasso4: Number,
  },

  mounted() {
    this.loadPasso4();

    emitter.on("cancelLoading", () => {
      this.isDisabled = this.isDisabledAll = this.showOverlay = false;
    });

    emitter.on("reloadListInsumos", (params) => {
      if (params.passo == 4) {
        this.listAdded.splice(this.listAdded.indexOf(params.item), 1);
        this.isDisabled = this.isDisabledAll = this.showOverlay = false;
        this.isDisabledAll = this.listAdded.length == 0;
      }
    });

    emitter.on("removeAllInsumos", (step) => {
      if (step == 4) {
        this.listAdded = [];
        this.isDisabled = this.isDisabledAll = this.showOverlay = false;
        this.hasInsumosAdded = this.listAdded.length != 0;
        this.$emit("hasInsumos", this.hasInsumosAdded);
        this.isDisabledAll = this.listAdded.length == 0;
      }
    });
  },

  methods: {
    loadPasso4() {
      RestituicaoService.getF100Adicionados(this.nCodOp).then((response) => {
        this.listAdded = response.data.dados;
        this.isDisabledAll = this.listAdded.length == 0;
        this.isLoaded = true;
        this.hasInsumosAdded = this.listAdded.length != 0;
        this.$emit("passo4", 4);
        this.$emit("hasInsumos", this.hasInsumosAdded);
      });
    },

    removeInsumo(item, step) {
      this.isDisabled = this.isDisabledAll = this.showOverlay = true;
      this.$emit("removeInsumo", { item, step });
    },

    callModalConfirmation(typeIcon, typeAction, typeList, step = 0) {
      this.isDisabled = this.isDisabledAll = this.showOverlay = true;
      this.$emit("callModalConfirmation", {
        typeIcon,
        typeAction,
        typeList,
        step,
      });
    },

    exportF100Notas() {
      this.isDisabledAll = this.isDisabled = this.showOverlay = true; 
      this.$emit("disabledBtnPasso", true);  
      RestituicaoService.exportF100Notas(this.nCodOp)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "text/csv",
            })
          );
          const msg = "F100 exportado com sucesso !";
          const type = "success";
          emitter.emit("makeModalDefault", { msg, type });

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", 'notas.csv');
          document.body.appendChild(link);
          link.click();
          this.isDisabledAll = this.isDisabled = this.showOverlay = false; 
          this.$emit("disabledBtnPasso", false);
        })
        .catch((err) => {
          const msg = "Erro ao exportar F100 Notas.";
          const type = "error";
          emitter.emit("makeModalDefault", { msg, type });
          this.isDisabledAll = this.isDisabled = this.showOverlay = false; 
          this.$emit("disabledBtnPasso", false);
        });
    },

    exportF100CTA() {
      this.isDisabledAll = this.isDisabled = this.showOverlay = true;
      this.$emit("disabledBtnPasso", true);
      RestituicaoService.exportF100Cta(this.nCodOp).then((response) => {
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "text/csv",
          })
        );
        const msg = "F100 exportado com sucesso !";
        const type = "success";
        emitter.emit("makeModalDefault", { msg, type });

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", 'cod_cta.csv');
        document.body.appendChild(link);
        link.click();
        this.isDisabledAll = this.isDisabled = this.showOverlay = false; 
        this.$emit("disabledBtnPasso", false);
      })
      .catch((err) => {
        const msg = "Erro ao exportar F100 CodCta.";
        const type = "error";
        emitter.emit("makeModalDefault", { msg, type });
          this.isDisabledAll = this.isDisabled = this.showOverlay = false; 
          this.$emit("disabledBtnPasso", false);
      });
    },

    exportF100() {
      this.isDisabledAll = this.isDisabled = this.showOverlay = true; 
      this.$emit("disabledBtnPasso", true);
      RestituicaoService.exportF100(this.nCodOp)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "text/csv",
            })
          );
          const msg = "F100 exportado com sucesso !";
          const type = "success";
          emitter.emit("makeModalDefault", { msg, type });

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${this.nCodOp}_f100.txt`);
          document.body.appendChild(link);
          link.click();
          this.isDisabledAll = this.isDisabled = this.showOverlay = false; 
          this.$emit("disabledBtnPasso", false);          
        })
        .catch((err) => {
          const msg = "Erro ao exportar F100.";
          const type = "error";
          emitter.emit("makeModalDefault", { msg, type });
          this.isDisabledAll = this.isDisabled = this.showOverlay = false; 
          this.$emit("disabledBtnPasso", false);
        });
    },

  },
};
</script>

<style></style>
