<template>
  <section id="restituicao">
    <div class="cabecalho d-flex justify-content-between">
      <Breadcrumb/>
    </div> 

    <div class="jumbotron">
      <!-- Navegação dos passos -->
      <div class="container-calculo">
        <Stepper class="stepper" :steps="this.steps" :currentStep="passo" @actualStep="passo=$event" :availableStep="passo+1"/>
      </div>

      <!-- Upload Arquivos -->
      <div v-if="passo == 1">
        <UploadComponentRestituicao @passo2="loadPasso2()" tese="Restituicao" />
      </div>

      <!-- Selecionar insumos -->
      <div v-else-if="passo == 2">
        <Passo2Restituicao :nCodOp="nCodOp" @passo2="passo = $event" :activatePasso2="passo" />
      </div>

      <!-- Preencher F100 -->
      <div v-else-if="passo == 3">
        <Passo3Restituicao :nCodOp="nCodOp" :activatePasso3="passo" @hasInsumos="hasContent = $event"
          @passo3="passo = $event" @callModalConfirmation="showModalConfirmation($event)"
          @removeInsumo="removeInsumo($event.item, $event.step)" @disabledBtnPasso="isDisabledBtnPassos = $event" />
      </div>

      <!-- Exportar F100 e Ver Adicionados -->
      <div v-else-if="passo == 4">
        <Passo4Restituicao :nCodOp="nCodOp" :activatePasso4="passo" @hasInsumos="hasContent = $event"
          @passo4="passo = $event" @callModalConfirmation="showModalConfirmation($event)"
          @removeInsumo="removeInsumo($event.item, $event.step)" @disabledBtnPasso="isDisabledBtnPassos = $event"/>
      </div>
    </div>
  </section>
</template>

<script>

import emitter from "@/services/emitter";
import RestituicaoService from "@/services/teses/restituicao.service";
import Passo2Restituicao from "@/Components/Teses/Restituicao/Passo2Restituicao.vue"
import Passo3Restituicao from "@/Components/Teses/Restituicao/Passo3Restituicao.vue"
import Passo4Restituicao from "@/Components/Teses/Restituicao/Passo4Restituicao.vue"
import UploadComponentRestituicao from "@/Components/UploadComponent/UploadComponentRestituicao.vue";
import Breadcrumb from "@/Components/Breadcrumb/Breadcrumb.vue";
import Stepper from "@/Components/Stepper/Stepper.vue";

export default {
  name: "restituicao",

  components: {
    Passo2Restituicao,
    Passo3Restituicao,
    Passo4Restituicao,
    UploadComponentRestituicao,
    Breadcrumb,
    Stepper,
  },

  data() {
    return {
      isDisabledBtnPassos: false,
      passo: 0,
      hasFiles: "",
      nCodOp: "",
      textPassoBtn: "Passo 2",
      hasContent: false,
      dot: ".",
      steps: [
                {
                    stepNumber: 1,
                    stepTitle: "Enviar Arquivos",
                },
                {
                    stepNumber: 2,
                    stepTitle: "Selecionar Insumos",
                },
                {
                    stepNumber: 3,
                    stepTitle: "Adicionar ao F100",
                },
                {
                    stepNumber: 4,
                    stepTitle: "Exportar F100",
                },
              ],
    }
  },

  watch: {
    hasFiles() {
      this.hasFiles ? this.loadPasso2() : this.loadPasso1();
    },
  },

  created() {
    this.nCodOp = this.$route.params.nCodOp;
  },

  computed: {
  },

  async mounted() {
    await this.checkFilesRestituicao();

  },

  methods: {
    async checkFilesRestituicao() {
      await RestituicaoService.checkFilesRestituicao(this.nCodOp)
        .then((resp) => {
          this.hasFiles = resp.data;
        })
        .catch((error) => {
          if (error.response.data != null) {
            this.$swal({
              icon: "error",
              title: "Erro ao verificar arquivos !",
              text: error.response.data.mensagem,
              type: "error",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok",
            })
              .then(() => {
                this.$router.push('/home');
              })
          } else {
            const msg = "Erro ao verificar arquivos";
            const type = "error";
            emitter.emit("makeModalDefault", { msg, type });
          }
        });
    },

    listarSpeds(){
      this.$router.push(`/listaspeds`);
    },

    btnNextPasso() {
      switch (this.passo) {
        case 1:
          this.textPassoBtn = "Passo 3"
          return this.passo = 2;
        case 2:
          this.textPassoBtn = "Passo 4"
          return this.passo = 3;
        case 3:
          return this.passo = 4;
      }
    },

    btnBackPasso() {
      switch (this.passo) {
        case 2:
          this.textPassoBtn = "Passo 2"
          return this.passo = 1;
        case 3:
          this.textPassoBtn = "Passo 3"
          return this.passo = 2;
        case 4:
          this.textPassoBtn = "Passo 4"
          return this.passo = 3;
      }
    },

    loadPasso1() {
      this.passo = 1;
      this.textPassoBtn = "Passo 2"
    },

    loadPasso2() {
      this.passo = 2;
      this.textPassoBtn = "Passo 3"
    },

    loadPasso3() {
      this.passo = 3;
      this.textPassoBtn = "Passo 4"
    },

    loadPasso4() {
      this.passo = 4;
    },

    removeInsumo(item, passo) {
      this.isDisabledBtnPassos = true;
      let dataInsumos = {
        NCodOp: item.nCodOp,
        CodCta: item.codCta,
        I050sToRemove: null,
      };

      RestituicaoService.removeInsumo(dataInsumos).then((response) => {
        if (response.data) {
          emitter.emit("reloadListInsumos", { item, passo });
          const msg = "Removido com sucesso";
          const type = "success";
          emitter.emit("makeModalDefault", { msg, type });
        } 
        else {
          emitter.emit("cancelLoading")
        }
        this.isDisabledBtnPassos = false;
      })
      .catch((error) => {
        if (error.response.data != null) {
          this.$swal({
            icon: "error",
            title: "Erro ao remover insumo!",
            text: error.response.data.mensagem,
            type: "error",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          })
          .then(() => {
            emitter.emit("cancelLoading")
            this.isDisabledBtnPassos = false;
          })
        } 
        else {
          const msg = "Erro ao remover insumo";
          const type = "error";
          emitter.emit("makeModalDefault", { msg, type });
          emitter.emit("cancelLoading")
        }
      });      
    },

    removeAllInsumos(list, passo) {
      let dataInsumos = {
        NCodOp: list[0].nCodOp,
        I050sToRemove: list,
      };
      RestituicaoService.removeInsumo(dataInsumos).then((response) => {
        if (response.data) {
          const msg = "Removido com sucesso";
          const type = "success";
          emitter.emit("makeModalDefault", { msg, type });
          emitter.emit("removeAllInsumos", passo);
        } 
        else {
          emitter.emit("cancelLoading")
        }
        this.isDisabledBtnPassos = false;
      })
      .catch((error) => {
        if (error.response.data != null) {
          this.$swal({
            icon: "error",
            title: "Erro ao remover insumo!",
            text: error.response.data.mensagem,
            type: "error",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok",
          })
          .then(() => {
            emitter.emit("cancelLoading")
            this.isDisabledBtnPassos = false;
          })
        } 
        else {
          const msg = "Erro ao remover insumo";
          const type = "error";
          emitter.emit("makeModalDefault", { msg, type });
        }
      });       
    },

    showModalConfirmation(params) {
      this.$swal({
        icon: params.typeIcon,
        title: 'Tem certeza ?',
        text: `Deseja ${params.typeAction} todos os itens ? `,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, desejo continuar!",
        cancelButtonText: `Cancelar`,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-outline-green mr-2',
          cancelButton: 'btn btn-outline-danger ml-2'
        },
      }).then((result) => {
        if (result.value) {
          this.isDisabledBtnPassos = true;
          if (params.typeAction == "adicionar") {
            emitter.emit("sendAllInsumos", params.typeList)
          } else if (params.typeAction == "remover") {
            this.removeAllInsumos(params.typeList, params.step);
          }
        } else {
          this.isDisabledBtnPassos = false;
          emitter.emit("cancelLoading")
        }
      });
    },

    dotAnimate() {
      setInterval(() => {
        setTimeout(() => {
          this.dot = ". ."
        }, 500)

        setTimeout(() => {
          this.dot = ". . ."
        }, 750)
      }, 1000)

      return this.dot
    }

  },
};
</script>

<style scoped>
/* ids */
#confirma-notas {
  position: fixed;
  box-shadow: 0px 1px 20px 0px #000;
  z-index: 10;
  left: 85vw;
  top: 90vh;
}

#restituicao {
  overflow-x: hidden !important;
}

#newPartForm>.input-group {
  margin: 1%;
}

#newPartForm>div>input {
  border: 0.5px solid #000;
}

/* Classes */
.container-calculo{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 50px;
    }
.modal-footer {
  justify-content: center !important;
}

.jumbotron {
  background-color: #fff;
}

.line {
  border: none;
}

.selected-insumo {
  border-color: #3da938 !important;
  color: #3da938 !important;
  font-weight: bold;
}

.selected-insumo:hover {
  border-color: #3da938 !important;
  color: #3da938 !important;
  background-color: rgba(73, 73, 73, 0.3) !important;
  font-weight: bold;
}

.selected-insumo>.btn {
  color: #3da938 !important;
}

/* Element */
</style>
