<template>
  <section>
    <div v-if="isLoaded">
      <div class="d-flex justify-content-center">
        <b-input class="w-25 mt-4 ml-2 mr-3 mb-3 form-control" v-model="searchWord" placeholder="Pesquisar insumos..."
          type="text" @keyup="filterByWord()"></b-input>
        <b-form-select class="w-25 mt-4 mb-3 form-control" @change="filterByStatus($event)" :value="0"
          :options="statusDesc" v-model="selectStatus"></b-form-select>
      </div>

      <div class="d-flex justify-content-end">
        <h5 class="mt-2 text-secondary">
          {{ this.totalItensInsumos }} insumos encontrados
        </h5>
      </div>

      <div class="w-50" v-if="hasInsumosExcel">
            <button class="m-1 btn btn-outline-blue"
              @click="loadExcel()">
              Lista Excel
            </button>

            <button class="m-1 btn btn-outline-blue"
              @click="loadOriginalList()">
              Lista Original
            </button>            
      </div>


      <div class="justify-content-center">
        <table v-if="hasInsumos" class="table">
          <thead>
            <tr>
              <th scope="col">Código CTA</th>
              <th scope="col">Descrição do Insumo</th>
              <th scope="col">Qtd. i250</th>
              <th scope="col" style="text-align: center; width: 200px;">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(insumo, index) in listInsumos" :key="index" @click.stop="geti250s(insumo)"
              style="cursor: pointer">
              <td>#{{ insumo.codCta }}</td>
              <td>{{ insumo.cta }}</td>
              <td>{{ insumo.i250s }}</td>
              <td style="width: 200px;">
                <Icon v-if="statusDesc[insumo.status] == 'Não visualizado'" :iconName='"circle-fill"' class="status-icon" style="color: #d1d1d1; margin-right: 10px;"/>
                <Icon v-if="statusDesc[insumo.status] == 'Selecionado'" :iconName='"circle-fill"' class="status-icon" style="color: #303084; margin-right: 10px;"/>
                <Icon v-if="statusDesc[insumo.status] == 'Finalizado'" :iconName='"circle-fill"' class="status-icon" style="color: #2BB673; margin-right: 10px;"/>
                {{ statusDesc[insumo.status] }}
              </td>
              
            </tr>
          </tbody>
        </table>

        <h2 v-else class="mt-5 text-secondary w-100 text-center">
          Nenhum arquivo/insumo enviado.
        </h2>

      </div>
    </div>

    <div v-else>
      <div class="p-5 d-flex justify-content-center">
        <h1 class="text-secondary d-flex align-items-end"><span class="mx-3">Carregando</span>
          <DotAnimation />
        </h1>
      </div>
    </div>

    <b-modal :title="i050selected.cta" size="xl" id="modal-exclusao-i250" ref="modal-exclusao-i250" scrollable>
      <b-tabs class="titleColor">
        <b-tab active class="pt-4" @click="reloadSearch()">

          <template #title >
            Insumos
            <span class="badge badge-pill badge-primary">
              {{ listI250Added.length }}
              <span class="sr-only">I250</span>
            </span>
          </template>

          <b-overlay :show="tablebusyi250">

            <div class="d-flex justify-content-left ">
              <b-input class="w-25 mt-1 ml-2 mr-3 mb-4 form-control" v-model="searchWordModal" placeholder="Pesquisar insumo" 
                type="text" @keyup="filterInsumoAdd()"></b-input>

              <b-form-select class="w-25 mt-1 ml-2 mr-3 mb-4 form-control" @change="filterModalAddByYear($event)" :value="0"
              :options="yearsModal" v-model="selectAdded"></b-form-select>

              <b-button variant="btn btn-outline-danger mt-1 ml-2 mr-3 mb-4" @click="removeI250Filter(listI250Added)">
                Remover itens filtrados
              </b-button>
            </div>

            <table class="table">
              
              <thead>
                <tr>
                  <th>Descrição</th>
                  <th>Data de lançamento</th>
                  <th>Valor do i250</th>
                  <th>Indicador de Créd.</th>
                </tr>
              </thead>
              
              <tbody>
                <tr style="cursor: pointer !important" v-for="(item, index) in listI250Added" :key="index"
                  @click.stop="removeI250(item)">
                  <td>{{ item.hist }}</td>
                  <td>{{ item.i200DtLcto }}</td>
                  <td>{{ formatMoney(item.vlDc) }}</td>
                  <td>{{ item.indDc }}</td>
                </tr>
              </tbody>
              
            </table>

          </b-overlay>
        </b-tab>

        <b-tab class="pt-4" lazy @click="reloadSearch()">
          <template #title>
            Removidos
            <span class="badge badge-pill badge-primary">
              {{ listI250Removed.length }}
              <span class="sr-only">Removidos</span>
            </span>
          </template>

          <b-overlay :show="tablebusyi250">

            <div class="d-flex justify-content-left ">
              <b-input class="w-25 mt-1 ml-2 mr-3 mb-4 form-control" v-model="searchWordModal" placeholder="Pesquisar insumo" 
                type="text" @keyup="filterInsumoRemoved()"></b-input>

              <b-form-select class="w-25 mt-1 ml-2 mr-3 mb-4 form-control" @change="filterModalRemoveByYear($event)" :value="0"
              :options="yearsModal" v-model="selectRemove"></b-form-select>

              <b-button variant="btn btn-outline-green mt-1 ml-2 mr-3 mb-4" @click="restorei250Filter(listI250Removed)">
                Restaurar itens filtrados
              </b-button>
            </div>          

            <table class="table">
              <thead>
                <tr>
                  <th>Descrição</th>
                  <th>Data de lançamento</th>
                  <th>Valor do i250</th>
                  <th>Indicador de Créd.</th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor: pointer !important" v-for="(item, index) in listI250Removed" :key="index"
                  @click.stop="restorei250(item)">
                  <td>{{ item.hist }}</td>
                  <td>{{ item.i200DtLcto }}</td>
                  <td>{{ formatMoney(item.vlDc) }}</td>
                  <b-td>{{ item.indDc }} </b-td>
                </tr>
              </tbody>
            </table>
          </b-overlay>
        </b-tab>
      </b-tabs>

      <template #modal-footer>
        <b-button variant="btn btn-outline-green" @click="i250Confirm()">
          Concluir
        </b-button>
        <b-button variant="btn btn-outline-danger" @click="$bvModal.hide('modal-exclusao-i250')">
          Cancelar
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import DotAnimation from "@/Components/Utils/DotAnimation.vue"
import emitter from "@/services/emitter";
import helperService from "@/services/helper.service";
import RestituicaoService from "@/services/teses/restituicao.service";
import Icon from "../../Icon/icon.vue";

export default {
  name: "Passo2Restituicao",

  components: {
    DotAnimation,
    Icon,
  },

  data() {
    return {

      searchWord: "",
      searchWordModal: "",
      selectAdded: "",
      selectRemove: "",
      selectStatus: "",
      listInsumos: [],
      listInsumosOriginal: [],
      backupListInsumos: [],
      totalItensInsumos: 0,
      hasInsumos: false,
      hasInsumosExcel: false,
      isLoaded: false,
      yearsModal: [],
      statusDesc: {
        0: "Todos",
        1: "Não visualizado",
        2: "Selecionado",
        3: "Finalizado",
      },
      i050selected: "",
      tablebusyi250: false,
      listI250Added: [],
      backupI250Added: [],
      listI250Removed: [],
      backupI250Removed: [],
      dot: ".",
      responseInsumos: null,
    };
  },

  props: {
    nCodOp: String,
    activatePasso2: Number,
  },

  watch: {
    activatePasso2(newValue) {
      if (newValue == 2) {
        this.loadPasso2();
        alert(newValue);
      }
    },
  },

  mounted() {
    this.loadPasso2();
    this.dotAnimate();
  },

  methods: {
    loadPasso2() {
      RestituicaoService.getInsumos(this.nCodOp, this.activatePasso2)
        .then((response) => {
          if (response.status == 200) {
            this.responseInsumos = response;

            //verifica se existe excel enviado
            if(response.data.dados.listaExcel.length > 0){
              this.loadExcel();  
            }    
            else{
              this.loadOriginalList();
            }
            this.hasInsumos = this.listInsumos.length != 0;
            this.hasInsumosExcel = this.listInsumosOriginal.length != 0;
          }
          else if (response.status == 204) {
            this.hasInsumos = this.listInsumos.length != 0;

            const msg = "Nenhum arquivo encontrado, tente enviar na tela de upload do passo anterior.";
            const type = "info";
            emitter.emit("makeModalDefault", { msg, type });
          }

          this.isLoaded = true;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data != null) {
            const title = "Erro ao buscar insumos !";
            const msg = error.response.data.mensagem;
            const type = "error";

            emitter.emit("makeModalWithBtnsAndTitle", { title, msg, type });
          } else {
            const msg = "Erro ao buscar insumos !";
            const type = "error";

            emitter.emit("makeModalDefault", { msg, type });
          }

          this.isLoaded = true;
        });

      this.$emit("passo2", 2);
    },

    geti250s(i050) {
      this.tablebusyi250 = true;
      this.i050selected = i050;
      this.yearsModal = []; 
      this.$refs["modal-exclusao-i250"].show();

      RestituicaoService.geti250(i050).then((response) => {
        this.listI250Added = response.data.dados.listaI250;
        this.listI250Removed = response.data.dados.listaI250Removidos;

        this.backupI250Added = response.data.dados.listaI250;
        this.backupI250Removed = response.data.dados.listaI250Removidos;
        
        this.yearsModal.push("Todos");
        this.listI250Added.forEach((item)=>{
          this.yearsModal.indexOf(item.i200DtLcto.slice(6, 10)) === -1 && this.yearsModal.push(item.i200DtLcto.slice(6, 10));
        });

        this.listI250Removed.forEach((item)=>{
          this.yearsModal.indexOf(item.i200DtLcto.slice(6, 10)) === -1 && this.yearsModal.push(item.i200DtLcto.slice(6, 10));
        });   

        this.selectAdded = null;
        this.selectRemove = null;  
        this.searchWordModal = "";           
        this.tablebusyi250 = false;
      });

    },

    async removeI250(item) {
      this.listI250Removed.push(item);
      this.listI250Added.splice(this.listI250Added.indexOf(item), 1);

      if(this.searchWordModal.length > 0){
        this.backupI250Added.splice(this.backupI250Added.indexOf(item), 1);
      }

      // await RestituicaoService.saveModalI250(
      //   this.listI250Removed,
      //   this.nCodOp,
      //   this.i050selected.codCta
      // );

      this.listInsumos[
        this.listInsumos.indexOf(this.i050selected)
      ].status = 2;     
      
      this.updateAnotherList();
    },
    
    addI250ToList(item) {
      this.listI250s.splice(this.listI250s.indexOf(item), 1);
      this.listi250removed.push(item);
    },

    async updateAnotherList() {
      if(this.listInsumosOriginal.length > 0){
        this.listInsumosOriginal[
            this.listInsumosOriginal.map(e => e.codCta).indexOf(this.i050selected.codCta) 
        ].status = 2;  
      }
      else{
        try {
            console.log('else updateAnotherList');
        } catch (error) {
          console.log('try catch');
          console.log(error);
        }
      }

    },

    async restorei250(item) {
      this.listI250Removed.splice(this.listI250Removed.indexOf(item), 1);
      this.listI250Added.push(item)

      if(this.searchWordModal.length > 0){
        this.backupI250Removed.splice(this.backupI250Removed.indexOf(item), 1);
      }
      await RestituicaoService.saveModalI250(
        this.listI250Removed,
        this.nCodOp,
        this.i050selected.codCta
      )

      this.listInsumos[
        this.listInsumos.indexOf(this.i050selected)
      ].status = 2;   
      this.updateAnotherList();

    },

    async removeI250Filter(listOriginal) {
      var listFilterToRemove = [];

      if(listOriginal.length > 0){
        listOriginal.forEach((element) => {
          listFilterToRemove.push(element);
        });

        await listFilterToRemove.forEach((item) => {
          this.listI250Removed.push(item);
          this.listI250Added.splice(this.listI250Added.indexOf(item), 1);
          this.backupI250Added.splice(this.backupI250Added.indexOf(item), 1);
        });
        
        this.reloadSearch();

        await RestituicaoService.saveModalI250(
          this.listI250Removed,
          this.nCodOp,
          this.i050selected.codCta
        );

        this.listInsumos[
          this.listInsumos.indexOf(this.i050selected)
        ].status = 2;  
        this.updateAnotherList();
      }
    },

    async restorei250Filter(listOriginal) {
      var listFilterToRestore = [];

      if(listOriginal.length > 0){
        listOriginal.forEach((element) => {
          listFilterToRestore.push(element);
        });

        await listFilterToRestore.forEach((item) => {
          this.listI250Removed.splice(this.listI250Removed.indexOf(item), 1);
          this.listI250Added.push(item);
          this.backupI250Removed.splice(this.backupI250Removed.indexOf(item), 1);
        });

        this.reloadSearch();

        await RestituicaoService.saveModalI250(
          this.listI250Removed,
          this.nCodOp,
          this.i050selected.codCta
        );       
        
        this.listInsumos[
          this.listInsumos.indexOf(this.i050selected)
        ].status = 2;     
        this.updateAnotherList();
      }
    },

    async i250Confirm() {
      this.$swal({
        icon: "info",
        title: `${this.i050selected.cta}`,
        position: "top-center",
        html: `${this.listI250Removed.length} I250s estão na lista de removidos`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Continuar e selecionar ",
        cancelButtonText: `Cancelar`,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-outline-green mr-2",
          cancelButton: "btn btn-outline-danger ml-2",
        },
      })
        .then((value) => {
          if (value) {
            RestituicaoService.saveModalI250(
              this.listI250Removed,
              this.nCodOp,
              this.i050selected.codCta
            );
            this.listI250Removed.length = 0;
            this.listI250Added.length = 0;
            this.listInsumos[
              this.listInsumos.indexOf(this.i050selected)
            ].status = 2;
            this.updateAnotherList();
            this.$refs["modal-exclusao-i250"].hide();
          }
        })
        .catch((err) => {
          emitter.emit("makeToast", { msg: err, type: "error" });
        });
    },

    loadExcel(){
      this.listInsumos = this.responseInsumos.data.dados.listaExcel;
      this.backupListInsumos = this.responseInsumos.data.dados.listaExcel;
      this.listInsumosOriginal = this.responseInsumos.data.dados.listaPlanoI050;
      this.totalItensInsumos = this.responseInsumos.data.dados.totalItensExcel;
      this.reloadSearchGeral();
    },

    loadOriginalList(){
      this.listInsumos = this.responseInsumos.data.dados.listaPlanoI050;
      this.backupListInsumos = this.responseInsumos.data.dados.listaPlanoI050;
      this.listInsumosOriginal = [];
      this.totalItensInsumos = this.responseInsumos.data.dados.totalItens;
      this.reloadSearchGeral();
    },

    reloadSearchGeral(){
      this.searchWord = "";
      this.selectStatus = null;
    },

    reloadSearch(){
      this.searchWordModal = "";
      this.listI250Added = this.backupI250Added;
      this.listI250Removed = this.backupI250Removed;
      this.orderListByYear();
      this.selectAdded = null;
      this.selectRemove = null;
    },

    filterByStatus(statusCod) {
      this.searchWord = "";
      this.listInsumos = this.backupListInsumos;
      if (statusCod != 0) {
        this.listInsumos = Object.keys(this.listInsumos)
          .map((key) => this.listInsumos[key])
          .filter((item) => item.status == statusCod);
      }
      this.totalItensInsumos = this.listInsumos.length;
    },

    filterModalAddByYear(yearFilter) {
      this.searchWordModal = "";
      this.listI250Added = this.backupI250Added;
      if (yearFilter != "Todos") {
        this.listI250Added = Object.keys(this.listI250Added)
          .map((key) => this.listI250Added[key])
          .filter((item) => item.i200DtLcto.slice(6, 10) == yearFilter);
      }
    },   

    filterModalRemoveByYear(yearFilter) {
      this.searchWordModal = "";
      this.listI250Removed = this.backupI250Removed;
      if (yearFilter != "Todos") {
        this.listI250Removed = Object.keys(this.listI250Removed)
          .map((key) => this.listI250Removed[key])
          .filter((item) => item.i200DtLcto.slice(6, 10) == yearFilter);
      }
    },    

    filterByWord() {
      this.listInsumos = this.backupListInsumos;
      this.selectStatus = null;

      if (this.searchWord.length != 0) {
        this.listInsumos = Object.keys(this.listInsumos)
          .map((key) => this.listInsumos[key])
          .filter((item) =>
            item.cta.toUpperCase().includes(this.searchWord.toUpperCase())
          );
      }
      this.totalItensInsumos = this.listInsumos.length;
    },

    filterInsumoAdd() {
      this.listI250Added = this.backupI250Added;
      this.selectAdded = null;
      this.selectRemove = null;

      if (this.searchWordModal.length != 0) {
        this.listI250Added = Object.keys(this.listI250Added)
          .map((key) => this.listI250Added[key])
          .filter((item) =>
            item.hist.toUpperCase().includes(this.searchWordModal.toUpperCase())
          );
      }
      
    },    

    filterInsumoRemoved() {
      this.listI250Removed = this.backupI250Removed;
      this.selectAdded = null;
      this.selectRemove = null;

      if (this.searchWordModal.length != 0) {
        this.listI250Removed = Object.keys(this.listI250Removed)
          .map((key) => this.listI250Removed[key])
          .filter((item) =>
            item.hist.toUpperCase().includes(this.searchWordModal.toUpperCase())
          );
      }
    },  

    dotAnimate() {
      setInterval(() => {
        setTimeout(() => {
          this.dot = ". ."
        }, 1000)

        setTimeout(() => {
          this.dot = ". . ."
        }, 2000)
      }, 3000)
    },

    orderListByYear(){
      this.listI250Added.sort(function (a, b) {
        if (a.i200DtLcto.slice(6, 10) > b.i200DtLcto.slice(6, 10)) {
          return 1;
        }
        if (a.i200DtLcto.slice(6, 10) < b.i200DtLcto.slice(6, 10)) {
          return -1;
        }
        return 0;
      });
      this.listI250Removed.sort(function (a, b) {
        if (a.i200DtLcto.slice(6, 10) > b.i200DtLcto.slice(6, 10)) {
          return 1;
        }
        if (a.i200DtLcto.slice(6, 10) < b.i200DtLcto.slice(6, 10)) {
          return -1;
        }
        return 0;
      });       
    },

    colorPillStatus(status) {
      let colorPill = "";
      switch (status) {
        case 1:
          colorPill = "#00B087";
          break;
        case 2:
          colorPill = "#00B087";
          break;
        case 3:
          colorPill = "success";
          break;
      }

      return colorPill;
    },

    formatMoney(number) {
      return helperService.formatMoneyPtBR(number);
    }

  },
};
</script>

<style scoped>
</style>
